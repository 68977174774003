import * as fbq from "./fpixel";

export const navigateTocheckout = () => {
    let url = window.location.href;
    if(!url.includes("webinar")) {
        return;
    }
    
    let slug = url.split("webinar/")[1];
    slug = slug.split("#")[0];
    slug = slug.split("?")[0];

    if(!slug) {
        return;
    }

    let pageData = localStorage.getItem("pageData_" + slug);
    pageData = JSON.parse(pageData);
    fbq.sendWebinarEvent("AddToCart", pageData);

    let coupon = pageData.defaultCoupon || "";
    window.location.href = `/event-checkout/${pageData.slug}?code=${coupon}`;
  } 

  export const getCheckoutLink = () => {
    let url = window.location.href;
    if(!url.includes("webinar")) {
        return null;
    }
    
    let slug = url.split("webinar/")[1];
    slug = slug.split("#")[0];
    slug = slug.split("?")[0];

    if(!slug) {
        return null;
    }

    let pageData = localStorage.getItem("pageData_" + slug);
    pageData = JSON.parse(pageData);
    fbq.sendWebinarEvent("AddToCart", pageData);

    let coupon = pageData.defaultCoupon || "";
    return `/event-checkout/${pageData.slug}?code=${coupon}`;
  } 
