import React, { useState } from "react";
import stylesBanner from "../events/BannerSmall.module.scss";
import styles from "./Faqs.module.css";
import stylesEvents from "./Events.module.scss"; // Assuming you've created a CSS module for styling

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { 
    generateGradientTextStyle,
    generateFAQHeaderStyle,
    generateFAQBodyStyle
 } from "./util/theme";

const Faq = ({ data, theme }) => {
  const [expandedItems, setExpandedItems] = useState(data.expandedKeys);

  const handleToggle = (expanded) => {
    setExpandedItems(expanded);
  };

  return (
    <div
      className="ptb-100"
    //   style={{ backgroundColor: theme.style === "light" ? "white" : "black" }}
    >
      <div className="container">
        <h2
          className={`text-center text-white ${stylesBanner["banner-small-content-heading"]}`}
        >
          {data.title.map((item) => (
            <span
              key={item.id}
              className={`${item.className ? stylesEvents[item.className] : ''} ${theme.style === 'light' ? stylesEvents["text-black-color"] : ''}`}
              style={
                !item.className
                  ? generateGradientTextStyle(theme.gradient1, theme.gradient2)
                  : {}
              }
            >
              {item.text}
            </span>
          ))}
        </h2>
        <div className="row g-4 my-4">
          <Accordion
            allowZeroExpanded
            preExpanded={expandedItems}
            onChange={handleToggle}
            style={{border: "none"}}
          >
            {data.content.map((faq) => (
              <AccordionItem
                uuid={faq.key}
                key={faq.key}
                className={styles["accordion-item-custom"]}
              >
                <AccordionItemHeading>
                  <AccordionItemButton
                    className={`${styles["accordion-button-custom"]} ${styles["curriculum-title-gradient"]}`}
                    style={generateFAQHeaderStyle(theme)}
                  >
                    {faq.question}
                    <div className={styles["arrow-container"]}>
                      <span
                        className={`${styles["arrow"]} ${expandedItems.includes(faq.key) ? styles["open"] : ""}`}
                        style={{
                            borderColor: theme.style === "light" ? theme.gradient1 : "white",
                          }}
                      />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  className={`${styles["accordion-body-custom"]} ${theme.style === "light" ? styles["accordion-body-custom-light"] : ""} ${expandedItems.includes(faq.key) ? "" : "accordion__panel--hidden"}`}
                  style={generateFAQBodyStyle(theme)}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
