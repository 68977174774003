export const generateGradientTextStyle = (gradient1, gradient2) => ({
    color: '#fff',
    textAlign: 'center',
    WebkitTextFillColor: 'transparent',
    backgroundImage: `linear-gradient(96.62deg, ${gradient1} 25%, ${gradient2})`,
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
  });

export const generateContentGridItemHeadingStyle = (gradient1, gradient2) => ({
    backgroundImage: `linear-gradient(96.62deg, ${gradient1} 25%, ${gradient2})`,
  });

export const generateRegisterButtonStyle = (gradient1, gradient2, textColorButton) => ({
    backgroundImage: `linear-gradient(101deg, ${gradient1}, ${gradient2})`,
    boxShadow: `0 10px 20px ${gradient2}70`,
    color: `${textColorButton}`
  });

export const generateAccordionHeaderStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundColor: theme.accordion,
        color: theme.accordionHeader,
        border: `1px solid ${theme.accordion}`,
      };
    } else {
      return {
        backgroundImage: `linear-gradient(${theme.accordion}, #000)`,
        border: `1px solid ${theme.accordion}`,
        color: `#fff`
      };
    }
  };

  export const generateAccordionBodyStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundColor: `${theme.accordion}20`,
        border: `1px solid ${theme.accordion}`,
        backgroundImage: 'none',
      };
    } else {
      return {
        backgroundImage: `linear-gradient(135deg, #000, ${theme.accordion}80)`,
        border: `1px solid ${theme.accordion}`,
      };
    }
  };

  export const generateFAQHeaderStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundImage: 'none',
        backgroundColor: `${theme.accordion}30`,
        color: `#333`,
        border: `1px solid ${theme.accordion}`,
      };
    } else {
    }
  };

  export const generateFAQBodyStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundImage: 'none',
        backgroundColor: `#eee`,
        border: `1px solid ${theme.accordion}`,
        backgroundImage: 'none',
      };
    } else {
    }
  };

  export const generateAccordionChipStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundColor: theme.accordionChipFill,
        color: theme.accordionChipText,
        border: `1px solid ${theme.accordion}`,
      };
    } else {
      return {
        backgroundImage: `linear-gradient(#000, ${theme.accordion})`,
        border: `1px solid ${theme.accordion}`,
        color: `#fff`
      };
    }
  };

  export const generateEventAttendeeStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundImage: `linear-gradient(135deg, #fff, #0eb58233)`,
        border: `1px solid #0eb582`,
        boxShadow: `0 0 10px 2px rgba(61, 61, 61, 0.07)`
      };
    } else {
      return {
        backgroundImage: `linear-gradient(135deg, #000, #0eb58233)`,
        border: `1px solid #0eb582`,
        boxShadow: `0 0 10px 2px rgba(61, 61, 61, 0.07)`
      };
    }
  };

  export const generateEventNonAttendeeStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundImage: `linear-gradient(135deg, #fff, #c22b3433)`,
        border: `1px solid #c22b34`,
        boxShadow: `0 0 10px 2px rgba(61, 61, 61, 0.07)`
      };
    } else {
      return {
        backgroundImage: `linear-gradient(135deg, #000, #c22b3433)`,
        border: `1px solid #c22b34`,
        boxShadow: `0 0 10px 2px rgba(61, 61, 61, 0.07)`
      };
    }
  };

  export const generateAccordionToolsStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundColor: `transparent`,
        backgroundImage: `linear-gradient(135deg, #fff, ${theme.gradient1}50)`,
        border: `1px solid ${theme.gradient1}50`
      };
    } else {
      return {
        backgroundColor: `transparent`,
        backgroundImage: `linear-gradient(135deg, #000, ${theme.gradient1}50)`,
        border: `1px solid ${theme.gradient1}50`
      };
    }
  };

  export const generateMentorStyle = (style, colorCode) => {
    if (style === "light") {
      return {
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
      };
    } else {
      return {
        backgroundImage: `linear-gradient(123deg, #000, ${colorCode}70)`,
        border: `1px solid ${colorCode}50`
      };
    }
  };

  export const generateCompanyIconStyle = (style, colorCode) => {
    if (style === "light") {
        return {
          backgroundColor: "white",
        //   border: `1px solid ${colorCode}50`,
        //   boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
        };
      } else {
        return {
            backgroundImage: `linear-gradient(123deg, #000, ${colorCode}50)`,
        };
        }
    };

  export const generateTestimonialCardStyle = (theme) => {
    if (theme.style === "light") {
        return {
        backgroundImage: `linear-gradient(123deg, #fff, ${theme.gradient1}30)`,
        border: `1px solid ${theme.gradient1}70`,
        backgroundColor: `#fff`,
        hover: {
            backgroundImage: `linear-gradient(123deg, #fff, ${theme.gradient1}50)`,
            border: `1px solid ${theme.gradient1}`,
            
            }
        };
    } else {
    return {
        backgroundImage: `linear-gradient(123deg, #000, ${theme.gradient1}30)`,
        border: `1px solid ${theme.gradient1}70`,
        backgroundColor: `#000`,
        hover: {
            backgroundImage: `linear-gradient(123deg, #000, ${theme.gradient1}50)`,
            border: `1px solid ${theme.gradient1}`,
            }
        };
    }
  };

  export const generateVideoTestimonialCardStyle = (theme) => {
    if (theme.style === "light") {
        return {
        backgroundColor: `#fff`,
        };
    } else {
    return {
        backgroundColor: `#000`,
        };
    }
  };

  export const generateTimelineStyle = (colorCode) => ({
    '--timeline-color': colorCode,
  });

  export const generateTimelineCardStyle = (theme) => {
    if (theme.style === "light") {
      return {
        backgroundColor: `${theme.gradient1}20`,
        border: `1px solid ${theme.gradient1}`,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
      };
    } else {
      return {
        backgroundColor: `#222`,
        border: `1px solid #444`,
      };
    }
  };

  export const generateBannerSmallImgStyle = (backgroundColor) => ({
    '--banner-bg-color': backgroundColor,
  });

  export const generateTextGradientStyle = (gradient1, gradient2) => ({
    '--gradient-color1': gradient1,
    '--gradient-color2': gradient2,
  });
  